<template>
  <div class="home" style="position:relative;">
    <div class="content">
      <div class="banner">
        <div class="topbox">
          <div class="textbox">
            <p>{{$t('home.bannerText')}}</p>
            <!-- <p>这是测试</p> -->
            <p>{{ $t('home.bannerText1') }}</p>
          </div>
          <router-link :to="{ path: '/marketplace/apps' }">
            {{ $t('home.link') }}
            <span>></span>
          </router-link>
        </div>
      </div>
      <div class="contentBox">
        <div class="contentTitle">
          <p>{{ $t('home.title_1') }}</p>
        </div>
        <div class="hidden-homeapps">
          <div class="hidden-apps-down">
            <div class="swiper-control">
              <div class="btn-pre btn-pre1 iconbg" slot="button-prev">
                <i class="icon-xiangzuo iconfont"></i>
              </div>
              <swiper :options="swiperOption1">
                <swiper-slide v-for="(product, index) in this.$store.state.homeHotData" :key='index'>
                  <Brick-Item :data="product"></Brick-Item>
                </swiper-slide>
              </swiper>
              <div class="btn-next btn-next1 iconbg" slot="button-next">
                <i class="icon-xiangyou1 iconfont"></i>
              </div>
            </div>
          </div>
          <div class="hidden-apps-up swiper-sm">
            <div class="swiper-control">
              <div class="btn-pre btn-pre1 iconbg" slot="button-prev">
                <i class="icon-xiangzuo iconfont"></i>
              </div>
              <swiper :options="swiperOption1_sm">
                <swiper-slide v-for="(product, index) in this.$store.state.homeHotData" :key='index'>
                  <Brick-Item :data="product"></Brick-Item>
                </swiper-slide>
              </swiper>
              <div class="btn-next btn-next1 iconbg" slot="button-next">
                <i class="icon-xiangyou1 iconfont"></i>
              </div>
            </div>
          </div>
        </div>
        <div class="hidden-mobilehomeapps mobileapps">
          <div class="swiper-control">
            <div class="btn-pre btn-pre1 iconbg" slot="button-prev">
              <i class="icon-xiangzuo iconfont"></i>
            </div>
            <swiper :options="swiperOption1_sm">
              <swiper-slide v-for="(product, index) in this.$store.state.homeHotData" :key='index'>
                <Brick-Item :data="product"></Brick-Item>
              </swiper-slide>
            </swiper>
            <div class="btn-next btn-next1 iconbg" slot="button-next">
              <i class="icon-xiangyou1 iconfont"></i>
            </div>
          </div>
        </div>
      </div>
      <div class="contentBox">
        <div class="contentTitle">
          <p>{{ $t('home.title_2') }}</p>
        </div>
        <div class="hidden-homeapps">
          <div class="hidden-apps-down">
            <div class="swiper-control">
              <div class="btn-pre btn-pre2 iconbg" slot="button-prev">
                <i class="icon-xiangzuo iconfont"></i>
              </div>
              <swiper :options="swiperOption2">
                <swiper-slide v-for="(product, index) in this.$store.state.homeSolutuionData" :key='index'>
                  <Brick-Item :data="product"></Brick-Item>
                </swiper-slide>
              </swiper>
              <div class="btn-next btn-next2 iconbg" slot="button-next">
                <i class="icon-xiangyou1 iconfont"></i>
              </div>
            </div>
          </div>
          <div class="hidden-apps-up swiper-sm">
            <div class="swiper-control">
              <div class="btn-pre btn-pre2 iconbg" slot="button-prev">
                <i class="icon-xiangzuo iconfont"></i>
              </div>
              <swiper :options="swiperOption2_sm">
                <swiper-slide v-for="(product, index) in this.$store.state.homeSolutuionData" :key='index'>
                  <Brick-Item :data="product"></Brick-Item>
                </swiper-slide>
              </swiper>
              <div class="btn-next btn-next2 iconbg" slot="button-next">
                <i class="icon-xiangyou1 iconfont"></i>
              </div>
            </div>
          </div>
        </div>
        <div class="hidden-mobilehomeapps mobileapps">
          <div class="swiper-control">
            <div class="btn-pre btn-pre2 iconbg" slot="button-prev">
              <i class="icon-xiangzuo iconfont"></i>
            </div>
            <swiper :options="swiperOption2_sm">
              <swiper-slide v-for="(product, index) in this.$store.state.homeSolutuionData" :key='index'>
                <Brick-Item :data="product"></Brick-Item>
              </swiper-slide>
            </swiper>
            <div class="btn-next btn-next2 iconbg" slot="button-next">
              <i class="icon-xiangyou1 iconfont"></i>
            </div>
          </div>
        </div>
      </div>
      <div class="contentBox">
        <div class="contentTitle">
          <p>{{ $t('home.title_3') }}</p>
        </div>
        <div class="hot">
          <el-row>
            <ul>
              <li v-for='(item, index) in hotProducts' :key='index' @click='toProductList(item)'>
                <el-col :xs="12" :sm="12" :md="6" :lg="6" :xl="6">
                  <div class="hotItem">
                    <img v-lazy="imageList[index].url">
                    <span>{{ item.name }}</span>
                  </div>
                </el-col>
              </li>
            </ul>
          </el-row>
        </div>
      </div>
      <div class="contentBox">
        <div class="axureMarket hidden-market-down">
          <div class="marketVideo">
            <video-player id="myVideo" class="myvideo video-player vjs-custom-skin" ref="videoPlayer" :playsinline="true"
              :options="playerOptions">
            </video-player>
          </div>
          <div class="list">
            <p class="what">{{ $t('home.title_4') }}</p>
            <p class="description">{{ $t('home.text') }}</p>
            <div class="detail">
              <img src="../../assets/image/list1.png" alt />
              <p>{{ $t('home.text1') }}</p>
              <!-- <p style="clear:both"></p> -->
            </div>
            <div class="detail">
              <img src="../../assets/image/list2.png" alt />
              <p>{{ $t('home.text2') }}</p>
              <!-- <p style="clear:both"></p> -->
            </div>
            <!-- <div class="detail">
              <img src="../../assets/image/list3.png" alt />
              <p>{{$t('home.text3')}}</p>
              <p style="clear:both"></p>
            </div> -->
            <div class="learnMore">
              <router-link :to="{ name: 'Introduction', params: {} }"> {{ $t('home.link_1') }}</router-link>
            </div>
          </div>
        </div>
        <div class="axureMarket-sm hidden-market-up">
          <div class="marketVideo">
            <video-player id="myVideo" class="myvideo video-player vjs-custom-skin" ref="videoPlayer" :playsinline="true"
              :options="playerOptions">
            </video-player>
          </div>
          <div class="list">
            <p class="what">{{ $t('home.title_4') }}</p>
            <p class="description">{{ $t('home.text') }}</p>
            <div class="detail">
              <img src="../../assets/image/list1.png" alt />
              <p>{{ $t('home.text1') }}</p>
              <!-- <p style="clear:both"></p> -->
            </div>
            <div class="detail">
              <img src="../../assets/image/list2.png" alt />
              <p>{{ $t('home.text2') }}</p>
              <!-- <p style="clear:both"></p> -->
            </div>
            <!-- <div class="detail">
              <img src="../../assets/image/list3.png" alt />
              <p>{{$t('home.text3')}}</p>
            </div> -->
            <div class="learnMore">
              <router-link :to="{ name: 'Introduction', params: {} }"> {{ $t('home.link_1') }}</router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="bottom">
        <div class="contentText">
          <p>{{ $t('home.text4') }}</p>
          <p>{{ $t('home.text5') }}</p>
          <div class="btn" @click="toPublish">{{ $t('home.btn_1') }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BrickItem from "@/components/Brickhome";
export default {
  name: "Home",
  components: {
    BrickItem: BrickItem,
  },
  data: () => {
    return {
      language: 2,
      swiperOption1: {
        slidesPerView: "auto",
        spaceBetween: 16,
        slidesPerGroup: 2,
        loopFillGroupWithBlank: true,
        navigation: {
          nextEl: ".btn-next1",
          prevEl: ".btn-pre1"
        }
      },
      swiperOption1_sm: {
        slidesPerView: "auto",
        spaceBetween: 16,
        slidesPerGroup: 1,
        slidesPerColumn: 2,
        loopFillGroupWithBlank: true,
        navigation: {
          nextEl: ".btn-next1",
          prevEl: ".btn-pre1"
        }
      },
      swiperOption2: {
        slidesPerView: "auto",
        spaceBetween: 16,
        slidesPerGroup: 2,
        loopFillGroupWithBlank: true,
        navigation: {
          nextEl: ".btn-next2",
          prevEl: ".btn-pre2"
        }
      },
      swiperOption2_sm: {
        slidesPerView: "auto",
        spaceBetween: 16,
        slidesPerGroup: 1,
        slidesPerColumn: 2,
        loopFillGroupWithBlank: true,
        navigation: {
          nextEl: ".btn-next2",
          prevEl: ".btn-pre2"
        }
      },
      products: {},
      solutions: {},
      hotProducts: {},
      imageList: [
        {
          type: 'compute',
          url: require('@/assets/image/VirtualMachine.svg')
        },
        {
          type: 'internet',
          url: require('@/assets/image/VirtualNetwork.svg')
        },
        {
          type: 'save',
          url: require('@/assets/image/Storage.svg')
        },
        {
          type: 'tool',
          url: require('@/assets/image/dev-tools.svg')
        },
        {
          type: 'safe',
          url: require('@/assets/image/ADFS.svg')
        },
        {
          type: 'saas',
          url: require('@/assets/image/SaaS.svg')
        },
        {
          type: 'sql',
          url: require('@/assets/image/Database.svg')
        },
        {
          type: 'all',
          url: require('@/assets/image/SearchGrid.svg')
        }
      ],
      playerOptions: {
        playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
        autoplay: false, //如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 导致视频一结束就重新开始。
        preload: 'none', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: 'zh-CN',
        aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [{
          type: "video/mp4",//这里的种类支持很多种：基本视频格式、直播、流媒体等，具体可以参看git网址项目
          src: "https://ampteststorage.blob.core.chinacloudapi.cn/video/oss-deploy-from-marketplace.mp4" //url地址
        }],
        poster: "https://ss0.bdstatic.com/70cFuHSh_Q1YnxGkpoWK1HF6hhy/it/u=2473952273,1037100719&fm=15&gp=0.jpg", //你的封面地址
        // poster:"../../assets/image/market.png",
        notSupportedMessage: '此视频暂无法播放，请稍后再试', //允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true,
          durationDisplay: true,
          remainingTimeDisplay: false,
          fullscreenToggle: true  //全屏按钮
        }
      }
    }
  },
  mounted() {
    var that = this
    this.timer = setTimeout(() => {
      that.timeout()
    }, 50)
  },
  methods: {
    timeout() {
      this.getHotProduct();
      this.$store.dispatch('getHomeSolution')
      this.$store.dispatch('getHomeProductData')
    },
    getHotProduct: function () {
      this.$http.get(this.$api.GETHOTCATEGORY, {
        params: {
          language: this.$store.state.language,
        }
      }).then(res => {
        if (res.data.code == 200) {
          this.hotProducts = res.data.data;
        } else {
          alert(rees.data.errorMessage)
          this.$router.push({ name: 'error-500' })
        }
      }).catch(err => {
        this.$router.push({ name: 'error-500' })
      })
    },
    toProductList(data) {
      if (data.type == 0) {
        this.$store.commit('handleChange', data.categoryId); //store/index.js
        this.$store.commit('languageChange', this.language);
        this.$store.dispatch('getHotProductList');
        this.$router.push({
          name: 'ProductSPage',
          params: {
            cname: data.categoryId
          }
        })
      } else if (data.type == 1) {
        this.$store.commit('checkedFilter', data.categoryId)
        this.$store.commit('addOtherId', data.categoryId);
        this.$store.dispatch('getProductDataList');
        this.$router.push({
          name: 'ProductFPage',
          query: {
            subCategoryIds: this.$store.state.subCategoryId.length === 0 ? undefined : this.$store.state.subCategoryId,
            search: this.$store.state.search['history'] == "" ? undefined : this.$store.state.search['history'],
            otherId: this.$store.state.otherId.length === 0 ? undefined : this.$store.state.otherId,
            categoryId: this.$store.state.activeNum == "" ? undefined : this.$store.state.activeNum,
          }
        })

      } else if (data.type == 2) {
        this.$router.push({
          name: 'ProductIndex',
        }) // ProductList Ipage,store/index.js
      }
    },
    toPublish() {
      this.$router.push({ path: '/publish' })
    },
  },
  beforeDistory() {
    this.$store.commit('showPermit', false);
    this.$store.commit('getThisData', {});
  }
}
</script>

<style lang="scss" scoped src='../../assets/css/Home.scss'></style>
<style>
/* 修改提示框样式 */
.tip {
  background-color: rgba(46, 121, 208, 1);
  border-color: rgba(46, 121, 208, 1);
}

.tip .el-message__icon {
  color: #fff;
}

.tip .el-message__content {
  color: #fff;
}

/* 验证小红星显示位置 */
.el-form-item.is-required:not(.is-no-asterisk)>.el-form-item__label:before {
  content: "" !important;
}

.el-form-item.is-required:not(.is-no-asterisk)>.el-form-item__label:after {
  content: "*";
  color: #f56c6c;
  margin-left: 6px;
}

#myVideo {
  width: 100%
}</style>
