import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home/Home'
import cssVars from 'css-vars-ponyfill'
cssVars({})
Vue.use(VueRouter)
const routes = [
    {
        path: '/',
        redirect: '/Home',

    },
    {
        path: '',
        redirect: '/Home',
        component: () => import('@/views/Index.vue'),
        children: [
            {
                path: '/Home',
                name: 'Home',
                component: Home,

            },
            {
                path: '/user/myfavourites',
                name: 'Collect',
                component: () => import('@/views/Product/Collect.vue'),

            },
            {
                path: '/marketplace/apps',
                name: 'ProductList',
                component: () => import('@/views/Product/ProductList.vue'),
                children: [
                    {
                        path: '/marketplace/apps',
                        name: 'ProductIndex',
                        component: () => import('@/views/Product/ProductList/ProductIndex.vue'),

                    },
                    {
                        path: '/marketplace/apps/category/:cname',
                        name: 'ProductSPage',
                        component: () => import('@/views/Product/ProductList/ProductSPage.vue'),

                    },
                    {
                        path: '/marketplace/apps/filter',
                        name: 'ProductFPage',
                        component: () => import('@/views/Product/ProductList/ProductFPage.vue'),

                    }
                ]
            },
            {
                path: '/marketplace/apps/:productUnionId/:planId?',
                name: 'Detail',
                component: () => import('@/views/Product/Detail'),
            },
            {
                path: '/marketplace/consulting-services/:productUnionId/:planId?',
                name: 'Detail',
                component: () => import('@/views/Product/Detail'),
            },
            {
                path: '/publish',
                name: 'EcologicalCenter',
                component: () => import('@/views/EcologicalCenter/EcologicalCenter'),

            },
            {
                path: `/:lang/sell/signup`,
                redirect: '/sell/signup'
            },
            {
                path: `/sell/signup`,
                name: 'Register',
                component: () => import('@/views/User/Register'),
            },
            {
                path: '/about',
                name: 'Introduction',
                component: () => import('@/views/Introduction/Introduction'),
            },
            {
                path: '/billingregion',
                name: 'Region',
                component: () => import('@/views/Product/Region'),

            },
            {
                path: '*',
                name: "error-404",
                component: () => import('@/views/Error/error-404.vue')
            },
            {
                path: '*',
                name: "error-500",
                component: () => import('@/views/Error/error-500.vue')
            }
        ]
    },
]
const routerPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return routerPush.call(this, location).catch(error => error)
}

export default new VueRouter({
    routes: routes,
    mode: 'history',
})
