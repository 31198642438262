<template>
  <div id="app">
      <router-view></router-view>
  </div>
</template>
<script>
export default {
  data() {
    return {

    }
  },

}
</script>
<style>
@import "./assets/font/iconfont/iconfont.css";
@import "./assets/css/responsive.scss";
</style>
<style media="screen">
.el-popup-parent--hidden {
  overflow: auto !important;
  padding: 0 !important;
}
</style>
<style lang="scss">
* {
  list-style: none;
  text-decoration: none;
  padding: 0;
  margin: 0;
  font-family: Microsoft YaHei;
}

#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  position: relative;
  overflow: hidden;
}

#nav {
  padding: 30px;
  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
textarea {
  -ms-overflow-style: none;
}
textarea::-webkit-scrollbar {
  display: none;
}
img[src=""],
img:not([src]) {
  opacity: 0;
  // border:none
}
html{
  
}
</style>
