module.exports = {
	topBanner: {
		selectItem: {
			market: "Marketplace",
			products: "Apps",
			b_service: "Apply",
			service: "Publish",
			more: "Learn More",
		},
		btn_group: {
			search: "Search",
			lang: "CN",
			login: "Login",
			submit: "Submit",
			placeholder: "search in the market",
			logout: "Sign Out",
			loginFail: "login fail",
		},
		loginItem: {
			favourites: "My Saved Items",
		},
	},
	footerBanner: {
		BCSS: "amp-support@oe.21vianet.com",

		azure: "Azure",
		whatAzure: "What is Azure",
		products: "Products",
		pricing: "Pricing",
		offerDetail: "Offer Detail",
		guidance: "Azure in China Purchas Guidance",
		video: "Video Center",
		solution: "Solutions",
		caase: "Case Studies",
		document: "Documentation",
		blog: "Blog",

		support: "Support",
		contactUs: "Contact Us",
		supportPlan: "Support Plan",
		dashboard: "Service Dashboard",
		legal: "Legal",
		agreement: "Service Level Agreement",
		icp: "ICP Filling",
		psr: "Public Security Registration",
		fc: "Fapiao Claim",
		faqs: "FAQs",
		wNew: "What is New",

		trust: "Trust Center",
		homepage: "Trust Center Homepage",
		security: "Security",
		privacy: "Privacy and Control",
		compliance: "Compliance",
		transparency: "Transparency",

		account: "Account",
		azurePortal: "Azure Portal",
		aportal: "Azure Portal",
		eaportal: "EA Portal",

		email: "Support By Email",

		globalAzure: "Visit Global Azure",
		shicp: "SH ICP Filing No. 13015306-25",
		psb: "PSB Filing No. 31011502002224",
		privacy: "Privacy",

		pA: "Get quick support",
		getSupport: "Follow us via WeChat Public Account",

		followUs: "Follow us via WeChat Public Account",
		weChat1: "WeChat Acc. of MS_CEChina",
		weChat2: "WeChat Acc. of VNET_AzureSupport",
	},
	register: {
		agreeText1: "I have read and agree on the",
		agreeText2: "Azure China Marketplace Publisher Agreement.",
		checkbox_group: {
			Professional: "IT Professional",
			Developers: "Developers",
			BusinessUsers: "Business Users",
		},
		options: {
			Service: "Custom service",
			Image: "Virtual machine image ",
			Template: "Azure resource manager template",
		},
		label: {
			Name: "Full Name",
			Email: "Email",
			Phone: "Phone",
			CompanyName: "Company Name",
			CompanyNameEnglish: "The English name of your company (Optional)",
			CompanyUrl: "Company Website",
			CompanyProfiles: "Display Name",
			M_ID: "Microsoft Partner Network Id (MPNID)",
			S_Code: "Uniform Social Credit Code",
			License: "Business License",
			S_Type: "What type of offer do you intend to publish",
			S_Name: "Offer Name",
			S_Description: "Offer Description",
			ob_User: "Who are the intended users of this app",
			captcha: "Verification Code",
			btn: "Send",
		},
		placeholder: {
			Name: "Please enter Full Name",
			Email: "Please provide your work email.",
			Phone: "Please enter Telphone Number",
			CompanyName:
				"Please enter Company Name (should be same with the name on your business license)",
			CompanyNameEnglish:
				"Please enter the English name of your company (if any) for later review.",
			CompanyUrl: "Please enter Company Website ",
			CompanyProfiles: "Please fill in a short name for your company",
			M_ID: "Please enter Microsoft Partner Network Id",
			S_Code: "Please enter Uniform Social Credit Code",
			S_Type: "Please select",
			S_Name: "plaese enter Offer Name",
			S_Description: "Please enter Offer description",
		},
		contentText: {
			title: "Tell us about your company",
			txt_1:
				"Interested in publishing on Azure Marketplace? Please share your information and we will follow up with you within 2 business days.",
			txt_2: "If you have questions, you can review our",
			txt_link1: "Publisher Guide",
			txt_3: "or",
			txt_link2: "contact us",
			txt_4: ".",
			txt_5:
				"If you already are a publisher, you can sign into your account on",
			txt_link3: "Publisher Portal",
			txt_6: "to publish.",
		},
		uploadText: {
			addFile: "Attachments",
		},
		rules: {
			mes_1: "Missing Value",
			mes_2: "please input the correct email address",
			mes_3: "Please select at least one",
			mes_3: "Please select at least one",
			mes_4: "The image format of business license should be jpg or png.",
			mes_5: "Business license shall not be larger than 2M.",
			mes_6: "Enter a valid Microsoft Partner Network Id",
			mes_7: "please enter a valid phone number",
		},
		message: {
			emailSuccess: "Send out.",
			emailError: "Sending failed, please retry.",
			success: "Submitted successfully",
			error: "Failure to submit",
			verifyCodeError: "Verification code is incorrect.",
			phoneError: "Phone Number is wrong.",
			uploadFileSuccess: "File uploaded successfully",
			uploadFileError: "File upload failed",
		},
	},
	home: {
		bannerText: "Welcome to Azure Marketplace",
		bannerText1: "Discover, try, and deploy the cloud software you want.",
		link: "Browse all apps",
		title_1: "Featured Apps",
		title_2: "Blue Cloud Release Zone",
		title_3: "Popular App Categories",
		title_4: "What is the Marketplace?",
		hot_type_1: "Virtual Machine Images",
		hot_type_2: "Networking",
		hot_type_3: "Storage",
		hot_type_4: "Developer tools",
		hot_type_5: "Security",
		hot_type_6: "Softeare as a service",
		hot_type_7: "Database",
		hot_type_8: "All categories",
		text: "The Azure China Marketplace is the premier destination for all your software needs - certified and optimized to run on Azure.",
		text1:
			"Find solutions you want, from open source container platforms to threat",
		text2: "Get technical support and customized services in quick response",
		text3: "One-click deploy high-quality apps with 1 RMB trail account",
		link_1: "Learn more >",
		text4: "Sell in the Azure Marketplace",
		text5:
			"Help customers find your software and services with a listing in Azure Marketplace, our online store for developers and IT pros looking for technical building blocks.",
		btn_1: "Publish with us",
	},
	productList: {
		product_type: "Product category",
		screen: "Refine",
		resultText: "Product results",
		resultText2: "",
		unfold: "See all",
		reset: "Reset filters",
		all: "All",
	},
	detail: {
		r_index: "",
		r_product: "",
		top: "Products",
		uncollected: "SAVE FOR LATER",
		collect: "SAVED",
		desc: "Product Description",
		more: "Learn more",
		text0: "Category",
		text1: "Legal",
		text2: "License Agreement",
		text3: "Privacy Policy",
		text4: "Service provider",
		text5: "Service Contact",
		text6: "Support",
		text7: "Service Email",
		text4_1: "Telephone",
		text4_2: "Email",
		text4_3: "WeChat",
		text4_4: "QQ",
		text4_5: "Weibo",
		plans_1: "Software plan",
		plans_2: "Description",
		price_1:
			"The cost of running this product is a combination of the selected software plan charges plus the Azure infrastructure costs for the virtual machines on which you will be running this software. Your Azure infrastructure price might vary if you have enterprise agreements or other discounts.",
		price_2: "To view pricing in a different currency, ",
		price_3: "change the billing country/region",
		price_4: ". Costs might vary by deployment region.",
		priceInfo: "Pricing information",
		priceVaries: "Cost of deployed template components",
		startAndFree: "Starting at Free",
		cost: "+ Azure infrastructure costs",
		billing: "Bring your own license",
		planInfo: "Software plan details",
		planInfo1: "Select a software plan",
		pricingbyvirtua: "Pricing by virtual machine instance",
		show: "show",
		pRecommment: "Publisher recommendations",
		virtual: "All virtual machine instances",
		resetFilter: "Reset Filters",
		filterDesc: "The publisher recommends the following",
		filterDesc2: "virtual machine instances for use with this software plan.",
		filterDesc3:
			"The publisher has not recommended any virtual machines for this deployment region.",
		cores: "Cores(1 to 128)",
		ram: "RAM(0GB to 3800GB)",
		mType: "Virtual machine category",
		diskSpace: "Disk Space",
		areas: "Region",
		diskType: "Dirver Type",
		disk: "Virtual Machine",
		setting: "Configuration",
		costPerHour: "Cost per hour",
		totalCost: "Total cost",
		tableList: [
			"instance",
			"Category",
			"	Cores",
			"RAM",
			"	Disk Space",
			"Drive Type",
			"Infrastructure Cost",
			"Software Cost",
			"Hourly",
			"Monthly",
		],
		chinaArea1: ["China East", "China East2", "China North", "China North2"],
		diskType_l: ["All", "HDD", "SDD"],
		diskSpace_l: [
			"All",
			"Small（under 100GB）",
			"Medium（100-600GB）",
			"Big（over 600GB）",
		],
		mType_l: [
			"All",
			"Standard",
			"Basic",
			"General Purpose",
			"Memory Optimized",
			"Compute Optimized",
			"GPU",
		],
		downCsv: "Download table as CSV",
		tableDesc:
			"*Premium storage is available for this type of virtual machine.",
		tableLink: "Learn more",
		minPrice: "starting at",
	},
	region: {
		title: "Select a billing country/region",
	},
	feedBack: {
		label0: "FEED",
		label1: "Service type",
		label2: "Support category",
		label3: "Service subject",
		label4: "Service details",
		label4_1: "Upload",
		label5: "Name",
		label6: "Email address",
		label7: "The Phone",
		label8: "Company name",
		placeholder: {
			type: "please select",
			subject: "please enter service subject",
			details: "please enter service details(less than 300 words)",
			name: "please enter full name",
			phone: "please enter phone",
			email: "please enter email",
			company: "please enter company name",
		},
		select: {
			type1: "Publisher Registration",
			// type2: 'Publisher Guide',
			// type3: 'China marketplace webpage/content',
			// type4: 'Other',
			type1_1: "About Eligibility",
			type1_2: "About Certification",
			type1_3: "About Progress",
			// type2_1: 'Correction of the content',
			// type2_2: 'Add content',
			// type2_3: 'About the content',
			// type3_1: 'Webpage debug',
			// type4_1: 'Other',
		},
		imgSizeError: "The file shall not be larger than 2M.",
		desc1:
			"You can ask questions about the registration of Azure China Marketplace via below form. If you are already the Publisher of Azure China Marketplace, please seek for the help through ",
		desc_link: "Azure China Support.",
		desc2: "",
	},
	brick: {
		btn: "Get it now",
		btn2: "Contact me",
		btn3: "Detail",
		btn4: "Buy now",
		desc: "Software plans start at",
		price: "Free",
		price1: "Bring your own license",
		price2: "Price varies",
	},
	//EcologicalCenter
	EcologicalCenter: {
		introduction: {
			txt_1: "Grow your cloud business with Azure China Marketplace",
			txt_1_1: "Reach more customers for your cloud solutions",
			txt_2:
				"Azure China Marketplace is a powerful channel to market and sell your cloud solutions certified to run on Azure China. Showcase virtual machine images and solution templates and get access to our top Azure customers worldwide.",
			btn: "GET STARTED",
			txt_3: "Already a partner?",
			toPartner: "Click here to enter the partner portal",
		},
		features: {
			channel_1: "Drive more leads",
			channel_2:
				"Generate leads by giving customers and prospects the ability to spin up live instances of your applications running on Azure in just minutes.",
			deliver_1: " Promote your solutions to enterprise",
			deliver_2:
				"Gain access to Microsoft enterprise sellers using the Azure China Marketplace as a selling tool. Participate in our co-marketing oppotunities to reach new customers and markets worldwide.",
			value_1: "Grow brand value",
			value_2:
				"Showcase your products and solutions to Azure customers all over the world.",
		},
		maintxt: {
			start: "Get started",
			publish_1: "Publish Your Solution",
			publish_2:
				"If you have an app that is built to extend Azure, or a service designed to build-on, customize, or manage Azure, learn more about how you can prepare and publish your offer on Azure Marketplace.",
			publish_btn: "Publisher Guide",
			sales_1: "Grow your business",
			sales_2:
				"A great listing and a test experience will help you attract and engage customers. Learn how to maximize your listing's impact and your go to market benefits.",
			sales_btn: "Grow Your Business Guide",
		},
		application: {
			appli_1: "Apply to get your app or service listed",
			appli_2:
				"Tell us about your app or service offer and our onboarding team will guide you through next steps",
			appli_btn: "SUBMIT YOUR OFFER",
		},
	},
	//Introduction
	Introduction: {
		market: {
			market_1: "What is Azure Marketplace?",
			market_2:
				"The premier destination for all your software needs-certified and optimized to run on Azure",
		},
		features: {
			find_1: "Find solutions you need",
			find_2:
				"Browse through Azure China Marketplace's rich catalog of products and end-to-end solutions from independent software vendors (ISVs). Get access to open source and enterprise applications that have been certified and optimized to run on Azure.",
			try_1: "Try for free",
			try_2:
				"Leverage the free trials from ISV's to deploy and use the software in your Azure subscription.",
			deploy_1: "Enterprise integration",
			deploy_2:
				"Get the architectural guidance and implementation help you need from Microsoft and our strong ecosystem of top system integrators and solutions architects. Enjoy a consistent procurement experience with Global Azure and reuse your existing software licenses with Bring Your Own License (BYOL) offers.",
			integration_1: "Deploy with confidence",
			integration_2:
				"Stand up an end to end solution reliably within a few minutes. At Azure marketplace, we work with each of the software vendor to ensure your experience installing and using the software on Azure is as seamless as possible.",
		},
	},
	//Login
	Login: {
		login_1: "Sign in to Azure Marketplace",
		login_2: "Use your Azure China account.",
		btn: "Sign in",
		login_3: "Do not have an account ?",
		login_4: "Sign up",
	},
	Info: {
		contact: "Email",
		title: "Please enter information",
		name: "Name",
		email: "Email",
		phone: "Phone",
		company: "Company",
		consult: "Consult",
		submit: "Submit",
		placeholder_c: "Please input the consultation content",
		text: "We do not yet support leads collection in Azure China Marketplace. Please reach the publisher through the contact information that publisher provided in the product description.",
	},
	productDialog: {
		t1: [
			"I agree to the provider's ",
			"terms of use",
			"and",
			"privacy policy",
			"and understand that the rights to use this product do not come from 21Vianet. Use of Azure Marketplace is governed by separate",
			"terms.",
			"",
		],
		btn: "Continue",
		price: "Price",
		detail: "Detail",
		creatApp: "Create this app in Azure",
		softPlan: "Software plan",
	},
	favourites: {
		title: "Saved Apps",
		text: "You haven't saved any Apps yet",
	},
};
