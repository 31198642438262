<template>
    <div v-if='data'>
        <div class="hidden-homeapps">
            <div class="productItem  hidden-apps-down">
                <router-link class="brick-item"
                    :to="{ name: 'Detail', params: { productUnionId: data.productUnionId, lang: lang } }">
                    <div class="figure">
                        <img v-lazy="data.imgUrl" alt="">
                    </div>
                    <div class="contentBox">
                        <div class="content-title">
                            <p class="name">{{ data.name }}</p>
                            <p class="company">{{ data.company }}</p>
                        </div>
                        <div class="content-price" v-if="data.price == 3">
                            <p class="desc">{{ $t('brick.desc') }}</p>
                            <p class="type">{{ $t('brick.price') }}</p>
                        </div>
                        <div class="content-price" v-else-if="data.price == 2">
                            <p class="type1">{{ $t('brick.price1') }}</p>
                        </div>
                    </div>
                </router-link>
                <div class="btn-box">
                    <div class="btn" @click="deploy(data.paramsData.id)" v-if="data.type == 1">{{ $t('brick.btn') }}</div>
                    <div class="btn" @click="contact(data.paramsData.id, data.name)" v-else-if='data.type == 2'>
                        {{ $t('brick.btn2') }}</div>
                    <div class="btn" v-else-if='data.type == 3' @click='aggreSmart(data.productUnionId, lang)'>
                        {{ $t('brick.btn3') }}</div>
                    <div class="collect" @click="toggle(data.productIdentity, data.collectStatus)" v-if="isLogin">
                        <i class="iconfont icon-uncollected" v-show="!data.collectStatus"></i>
                        <i class="iconfont icon-collect" v-show="data.collectStatus"></i>
                    </div>
                    <el-dialog :visible.sync="dialogTableVisible" center :append-to-body='true'>
                        <Login></Login>
                    </el-dialog>
                    <el-dialog :visible.sync="dialogTableVisibleinfo" center :append-to-body='true'>
                        <Info v-on:showIt='showDialog' :product="data"></Info>
                    </el-dialog>
                </div>
            </div>
            <div class="productItem-sm hidden-apps-up">
                <router-link :to="{ name: 'Detail', params: { productUnionId: data.productUnionId, lang: lang } }">
                    <div class="figure">
                        <img v-lazy="data.imgUrl" alt="">
                    </div>
                    <div class="contentBox">
                        <p class="name">{{ data.name }}</p>
                        <p class="company">{{ data.company }}</p>
                    </div>
                </router-link>
                <div class="collect" @click="toggle(data.productIdentity, data.collectStatus)" v-if="isLogin">
                    <i class="iconfont icon-uncollected" v-show="!data.collectStatus"></i>
                    <i class="iconfont icon-collect" v-show="data.collectStatus"></i>
                </div>
            </div>
        </div>
        <div class="mobileapps hidden-mobilehomeapps">
            <router-link :to="{ name: 'Detail', params: { productUnionId: data.productUnionId, lang: lang } }">
                <div class="figure">
                    <img v-lazy="data.imgUrl" alt="">
                </div>
                <div class="contentBox">
                    <p class="name">{{ data.name }}</p>lang
                    <p class="company">{{ data.company }}</p>
                </div>
            </router-link>
            <div class="collect" @click="toggle(data.productIdentity, data.collectStatus)" v-if="isLogin">
                <i class="iconfont icon-uncollected" v-show="!data.collectStatus"></i>
                <i class="iconfont icon-collect" v-show="data.collectStatus"></i>
            </div>
        </div>
    </div>
</template>

<script>
import Login from '@/components/Login'
import Info from '@/components/Info'
export default {
    name: 'Brickhome',
    props: ['data'],
    components: {
        Login,
        Info
    },
    data() {
        return {
            dialogTableVisible: false,
            dialogTableVisibleinfo: false,
            isLogin: '',
            iscolllect: false,
        }
    },
    computed: {
        lang() {
            var lang
            sessionStorage.lang === 'en' ? lang = 'en-us' : lang = 'zh-cn'
            return lang
        }
    },
    mounted() {
        this.timer = setTimeout(() => {
            this.isLogin = localStorage.loginInfo
        }, 100)
    },
    methods: {
        aggreSmart(id, lang) {
            this.$router.push({ name: 'Detail', params: { productUnionId: id, lang: lang } })
        },
        toggle(productIdentity, collectStatus) {
            this.data.collectStatus = !collectStatus;
            this.$store.dispatch('collectProdcut', { productIdentity, likeStatus: !collectStatus }).then(() => {
                this.data.collectStatus = !collectStatus
            }).catch(() => {
                this.data.collectStatus = collectStatus
            })
        },
        deploy() {
            if (localStorage.loginInfo) {
                var newData = {
                    ...this.data,
                    name: this.data.productName,
                    company: this.data.publisherDisplayName,
                    path: "/marketplace/apps/detail",
                    imgUrl: this.data.largeIcon,
                    paramsData: {
                        id: this.data.id,
                    },
                    createUrl: this.data.createAppUrl,
                };
                this.$store.commit("getThisData", newData);
                this.$store.commit("showPermit", true);
            } else {
                this.dialogTableVisible = true;
                this.$emit("getData", "");
            }
        },
        contact(id, productName) {
            this.dialogTableVisibleinfo = true;
            this.$store.commit('setBrickId', id);
            this.$store.commit('setBrickName', productName)
        },
        showDialog(bool) {
            this.dialogTableVisibleinfo = bool;
        }
    }
}
</script>

<style lang="scss" scoped>
/deep/ .el-dialog--center .el-dialog__body {
    padding: 25px 40px 25px;
}

.productItem {
    border: 1px solid rgb(233, 230, 230);
    width: 193px;

    .productItem:hover {
        box-shadow: 2px 2px 20px 10px rgba(206, 205, 205, 0.2);
    }

    .brick-item {
        width: 193px;
        display: block;
        height: 202px;
        transition: all 0.8s;
        background: rgba(255, 255, 255, 1);
        cursor: pointer;
    }

    .figure {
        height: 60px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid rgb(241, 239, 239);
    }

    .figure img {
        width: 40px;
        height: 40px;
        margin-left: 15px;
    }

    .contentBox {
        position: relative;
        padding: 0 15px;
    }

    .contentBox p {
        white-space: wrap;
        overflow: hidden;
    }

    .contentBox .content-title {
        height: 94px;
    }

    p.name {
        margin-top: 6px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        color: rgba(51, 51, 51, 1);
    }

    p.company {
        font-size: 10px;
        font-family: Microsoft YaHei;
        color: rgba(102, 102, 102, 1);
        line-height: 24px;
    }

    p.desc {
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: rgba(102, 102, 102, 1);
        line-height: 24px;
    }

    p.type {
        font-size: 14px;
        font-family: Microsoft YaHei;
        line-height: 16px;
        color: #000;
    }

    p.type1 {
        padding-top: 16px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        line-height: 16px;
        color: #000;
    }

    .btn-box {
        width: 193px;
        height: 48px;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        border-top: 1px solid rgb(241, 239, 239);
        position: relative;
    }

    div.btn {
        width: 100px;
        height: 26px;
        line-height: 26px;
        border-radius: 4px;
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        color: rgba(46, 121, 208, 1);
        transition: all 0.5s;
        cursor: pointer;
    }

    div.btn:hover {
        border: 1px solid rgba(46, 121, 208, 1);
        background: rgba(46, 121, 208, 1);
        color: #fff;
    }

    .collect {
        position: absolute;
        right: 10px;
        top: 16px;

        .icon-uncollected {
            color: rgba(46, 121, 208, 1);
        }

        .icon-collect {
            color: rgba(46, 121, 208, 1);
        }
    }
}

.productItem-sm {
    border: 1px solid rgb(241, 239, 239);
    padding-left: 20px;
    width: 314px;
    height: 78px;
    display: flex;
    position: relative;

    a {
        display: flex;
        align-items: center;
        width: 314px;
        height: 80px;

        .figure {
            width: 50px;
            margin-right: 20px;

            img {
                width: 50px;
                height: 50px;
            }
        }

        .contentBox {
            color: black;
            font-size: 12px;

            .company {
                margin-top: 4px;
                font-size: 10px;
                color: rgb(117, 115, 115);
            }
        }
    }

    .collect {
        position: absolute;
        bottom: 10px;
        right: 10px;

        .icon-uncollected {
            color: rgba(46, 121, 208, 1);
        }

        .icon-collect {
            color: rgba(46, 121, 208, 1);
        }
    }
}

.mobileapps {
    border: 1px solid rgb(241, 239, 239);
    padding-left: 20px;
    width: 318px;
    height: 78px;
    display: flex;
    position: relative;

    a {
        display: flex;
        align-items: center;
        width: 318px;
        height: 80px;

        .figure {
            width: 50px;
            margin-right: 20px;

            img {
                width: 50px;
                height: 50px;
            }
        }

        .contentBox {
            color: black;
            font-size: 12px;

            .company {
                margin-top: 4px;
                font-size: 10px;
                color: rgb(117, 115, 115);
            }
        }
    }

    .collect {
        position: absolute;
        bottom: 10px;
        right: 10px;

        .icon-uncollected {
            color: rgba(46, 121, 208, 1);
        }

        .icon-collect {
            color: rgba(46, 121, 208, 1);
        }
    }
}

a {
    color: rgba(0, 0, 0, 0);
}
</style>
