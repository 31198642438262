import axios from 'axios'
import api from './api.js'
import qs from 'qs'

axios.interceptors.request.use(
  config => {
    if (config.url == "AMPToJarvisAPI/UploadFile" || config.url == 'AMPToJarvisAPI/Create') {
      config.url = api.jarvis + config.url;
      config.headers['Authorization'] = `Bearer ${localStorage.getItem('_biz_token_')}`
    } else if (config.url == 'AMPToJarvisAPI/token') {
      config.url = api.jarvis + config.url;
      config.headers['content-type'] = 'application/x-www-form-urlencoded';
    } else if (config.url.includes('RuZhuXinXi') || config.url.includes('SMS')) {
      config.url = api.jarvis + config.url
    } else {
      config.url = api.baseUrl + config.url;
      localStorage.loginInfo ? config.headers.UserId = JSON.parse(localStorage.loginInfo).userId : null;
    }
    if (config.method === 'get') {
      config.paramsSerializer = function (params) {
        return qs.stringify(params, {
          arrayFormat: 'repeat'
        });
      };
    }
    return config;
  },
  error => {
    if (error && error.response) {
      switch (error.response.status) {
        case 400: error.message = "请求错误(400)"; break;
        case 403: error.message = "拒绝访问(403)"; break;
        case 404: error.message = "请求出错(404)"; break;
        case 408: error.message = "请求超时(408)"; break;
        case 500: error.message = "服务器错误(500)"; break;
        case 501: error.message = "服务未实现(501)"; break;
        case 502: error.message = "网络错误(502)"; break;
        case 503: error.message = "服务不可用(503)"; break;
        case 504: error.message = "网络超时(504)"; break;
        case 505: error.message = "HTTP版本不受支持(505)"; break;
        default: error.message = `连接出错(${error.response.status})!`;
      }
    } else {
      error.message = "连接服务器失败!"
    }
    return Promise.reject(error)
  })
axios.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error && error.response) {
      switch (error.response.status) {
        case 400: error.message = '请求错误(400)'; break;
        case 403: error.message = "拒绝访问(403)"; break;
        case 404: error.message = "请求出错(404)"; break;
        case 408: error.message = "请求超时(408)"; break;
        case 500: error.message = "服务器错误(500)"; break;
        case 501: error.message = "服务未实现(501)"; break;
        case 502: error.message = "网络错误(502)"; break;
        case 503: error.message = "服务不可用(503)"; break;
        case 504: error.message = "网络超时(504)"; break;
        case 505: error.message = "HTTP版本不受支持(505)"; break;
        default: error.message = `连接出错(${error.response.status})!`;
      }
    } else {
      error.message = "连接服务器失败!"
    }
    return Promise.reject(error, error.response.status)
  }
)
export default axios
