import Vue from 'vue'
import Vuex from 'vuex'
import axios from '@/utils/request/request.js'
import api from '@/utils/request/api'
import createPersistedState from "vuex-persistedstate"
import router from '../router'
Vue.use(Vuex)
export default new Vuex.Store({
    state: {
        activeNum: "", //折叠面板
        productTypeList: [], //产品类型
        filterList: [], //筛选字段
        search: {
            label: '',
            checked: false,
            type: 'text',
            history: ''
        }, //搜索框内容
        otherId: [],//全部产品筛选中非折叠面板内的checkedBox对应的id
        subCategoryId: [],//全部产品筛选中折叠面板内的checkedBox对应的id
        spageData: [],//productSpage页面产品
        fpageData: [],//productfpage页面产品
        pageIndex: 1,//productfpage.vue翻页页码
        pageSize: 20,//productfpage.vue单页个数
        total: 0,//productfpage.vue总个数
        language: 2,//当前的语言
        allCounts: 0,//spage页产品总数
        brickId: '',//联系服务商点击保存的产品id
        brickName: '',//联系服务商点击保存的产品名称
        thisData: "",//productDialog接受的参数
        isShowPermit: false,//productDialog的显示与隐藏
        collectcounts: 0,//收藏个数
        keepAlive: ['Detail', 'Collect', 'Register'],//控制页面是否使用缓存
        homeHotData: [],//首页热门
        homeSolutuionData: [],//首页解决方案
        pageIndexData: [],//pageIndex页数据
        jarvisToken: '',
        refresh_jarvisToken: '',
        collectionProductList: [],
        checkedAllCategoryId: '',
    },
    getters: {

    },
    mutations: {
        setCheckedAllCategoryId(state, id) {
            state.checkedAllCategoryId = id
        },
        clearCheckedAllCategoryId(state) {
            state.checkedAllCategoryId = ''
        },
        setJarvisToken(state, token) {
            state.jarvisToken = token
        },
        setRefreshJarvisToken(state, token) {
            state.refresh_jarvisToken = token
        },
        setServiceEmail(state, email) {
            state.serviceEmail = email;
        },
        addCollectcounts(state) {
            state.collectcounts++;
        },
        subtractCollectcounts(state) {
            state.collectcounts > 0 ? state.collectcounts-- : state.collectcounts = 0
        },
        setCollectcounts(state, num) {
            state.collectcounts = num
        },
        getThisData(state, data) {
            state.thisData = data
        },
        showPermit(state, bool) {
            state.isShowPermit = bool
        },
        setBrickId(state, data) {
            state.brickId = data;
        },
        setBrickName(state, data) {
            state.brickName = data
        },
        getAllCounts(state, data) {
            state.allCounts = data;
        },
        getSearchhistory(state, data) {
            state.search.history = data;
        },
        getProductType(state, data) {
            state.productTypeList = data;
        },
        getFilter(state, data) {
            state.filterList = data;
        },
        handleChange(state, data) {
            state.activeNum = data;
        },
        languageChange(state, data) {
            state.language = data;
        },
        inputSearch(state, data) {
            state.pageIndex = 1
            state.search.label = data;
        },
        showSearchTag(state, data) {
            state.search.checked = data
        },
        changeCheckedState(state, data) {
            state.productTypeList.forEach(function (ele) {
                ele.subCategoryList.forEach(function (val) {
                    val.checked = data
                });
            });
        },
        changeFilterIdCheckedState(state, data) {
            state.filterList.forEach(function (ele) {
                ele.subCategoryList.forEach(function (val) {
                    val.checked = data
                })
            })
        },
        checkedFilter(state, id) {
            state.filterList.forEach(function (ele) {
                ele.subCategoryList.forEach(function (val) {
                    if (val.id == id) {
                        val.checked = true;
                    }
                })
            });
        },
        //首页的筛选
        checkedhomeFilter(state, id) {
            state.filterList.forEach(function (ele) {
                ele.subCategoryList.forEach(function (val) {
                    if (val.id == id) {
                        val.checked = true;
                    }
                })
            })
        },
        checkedItem(state, id) {
            state.pageIndex = 1
            state.productTypeList.forEach(function (ele) {
                ele.subCategoryList.forEach(function (val) {
                    if (val.id == id) {
                        state.activeNum = ele.id;
                        val.checked = true;
                    }
                });
            });
        },
        addSubCategoryId(state, id) {
            if (id === null) return;
            if (state.subCategoryId.indexOf(id) == -1) {
                state.pageIndex = 1
                state.subCategoryId.push(id)
            } else {
                state.subCategoryId.splice(state.subCategoryId.indexOf(id), 1);
            }
        },
        clearSubcategoryId(state, bool) {
            if (bool) {
                state.subCategoryId = []
            }
        },
        addOtherId(state, id) {
            if (state.otherId.indexOf(id) == -1) {
                state.pageIndex = 1
                state.otherId.push(id);
            } else {
                state.otherId.splice(state.otherId.indexOf(id), 1);
            }
        },
        clearOtherId(state, bool) {
            if (bool) {
                state.otherId = []
            }
        },
        addSpageData(state, data) {
            state.spageData = data
        },
        addFpageData(state, data) {
            state.fpageData = data
        },
        changePageSize(state, data) {
            state.pageSize = data
        },
        changePageIndex(state, data) {
            state.pageIndex = data
        },
        changeTotal(state, data) {
            state.total = data
        },
        bindActiveFilterNum(state, data) {
            state.activeFilterNum = data
        },
        Emptylabel(state, data) {
            state.search.label = data;
        },
        setKeepAlive(state, array) {
            state.keepAlive = array
        },
        setHomePageHotData(state, data) {
            state.homeHotData = data
        },
        setHomePageSolutionData(state, data) {
            state.homeSolutuionData = data
        },
        setPageIndexData(state, data) {
            state.pageIndexData = data
        },
        setCollectionProductList(state, data) {
            state.collectionProductList = data
        }
    },
    actions: {
        //fpage页数据
        getProductDataList(context) {
            axios.get(api.FILTERPRODUCT, {
                params: {
                    subCategoryIds: context.state.subCategoryId.length === 0 ? null : context.state.subCategoryId,
                    pageIndex: context.state.pageIndex - 1,
                    pageSize: context.state.pageSize,
                    searchName: context.state.search['history'],
                    otherId: context.state.otherId.length === 0 ? null : context.state.otherId,
                    categoryId: context.state.activeNum
                }
            }).then(res => {
                if (res.data.code == 200) {
                    res.data.data.productList = res.data.data.productList.map(function (val) {
                        return {
                            ...val,
                            price: val.priceType,
                            name: val.productName,
                            company: val.publisherDisplayName,
                            path: "Detail",
                            imgUrl: val.smallIcon,
                            paramsData: {
                                id: val.id
                            },
                            createUrl: val.createAppUrl ? val.createAppUrl : null,
                            desc: val.offerSummary,
                        };
                    })
                    context.commit('addFpageData', res.data.data.productList)
                    context.commit('changeTotal', res.data.data.productCount)
                    context.commit('Emptylabel', '')
                } else {
                    alert(res.data.errorMessage)
                    router.push({ name: 'error-500' })
                }
            }).catch((err) => {
                router.push({ name: 'error-500' })
            })
        },
        //spage页数据
        getHotProductList(context) {
            axios.get(api.GETHOTPRODUCTLIST, {
                params: {
                    categoryId: context.state.activeNum,
                    language: context.state.language
                }
            }).then(res => {
                if (res.data.code == 200) {
                    res.data.data.subCategoryHotProductList.forEach(function (value, idx) {
                        value.productList = value.productList.map(val => {
                            return {
                                ...val,
                                price: val.priceType,
                                name: val.productName,
                                company: val.publisherDisplayName,
                                path: "Detail",
                                imgUrl: val.smallIcon,
                                paramsData: {
                                    id: val.id
                                },
                                createUrl: val.createAppUrl ? val.createAppUrl : null,
                                desc: val.offerSummary,
                            };

                        })
                    })
                    context.commit('getAllCounts', res.data.data.allCount)
                    context.commit('addSpageData', res.data.data.subCategoryHotProductList)
                } else {
                    alert(res.data.errorMessage)
                    router.push({ name: 'error-500' })
                }

            }).catch((err) => {
                router.push({ name: 'error-500' })
            })
        },
        //home页数据
        getHomeProductData(context) {
            axios.get(api.GETFEATUREPRODUCT).then(res => {
                if (res.data.code == 200) {
                    res.data.data.productList = res.data.data.productList.map(val => {
                        return {
                            ...val,
                            price: val.priceType,
                            name: val.productName,
                            company: val.publisherDisplayName,
                            path: "Detail",
                            imgUrl: val.smallIcon,
                            paramsData: {
                                id: val.id
                            },
                            createUrl: val.createAppUrl ? val.createAppUrl : null,
                            desc: val.offerSummary,
                        };
                    });
                    // this.products = res.data.productList;
                    context.commit('setHomePageHotData', res.data.data.productList)
                } else {
                    alert(res.data.errorMessage)
                    router.push({ name: 'error-500' })
                }
            }).catch(err => {
                router.push({ name: 'error-500' })
            });
        },
        //home页解决方案数据
        getHomeSolution(context) {
            axios.get(api.GETHOMEPAGESOLUTION).then(res => {
                if (res.data.code == 200) {
                    res.data.data.productList = res.data.data.productList.map(val => {
                        return {
                            ...val,
                            price: val.priceType,
                            name: val.productName,
                            company: val.publisherDisplayName,
                            path: "Detail",
                            imgUrl: val.smallIcon,
                            paramsData: {
                                id: val.id
                            },
                            createUrl: val.createAppUrl ? val.createAppUrl : null,
                            desc: val.offerSummary,
                        };
                    });
                    context.commit('setHomePageSolutionData', res.data.data.productList)
                } else {
                    alert(res.data.errorMessage)
                    router.push({ name: 'error-500' })
                }
            }).catch(err => {
                router.push({ name: 'error-500' })
            });
        },
        //productIndex页面数据
        getProductIndexData(context) {
            axios.get(api.GETALLPRODUCTLIST, {
                params: { language: context.state.language }
            }).then(res => {
                if (res.data.code == 200) {
                    res.data.data.subCategoryHotProductList.forEach(function (value, idx) {
                        value.productList = value.productList.map(val => {
                            return {
                                ...val,
                                price: val.priceType,
                                name: val.productName,
                                company: val.publisherDisplayName,
                                path: 'Detail',
                                imgUrl: val.smallIcon,
                                paramsData: {
                                    id: val.id
                                },
                                createUrl: val.createAppUrl,
                            }
                        })
                    });
                    context.commit('setPageIndexData', res.data.data.subCategoryHotProductList)
                } else {
                    alert(res.data.errorMessage)
                    router.push({ name: 'error-500' })
                }
            }).catch(err => {
                router.push({ name: 'error-500' })
            })
        },
        // 获取收藏数据及个数
        getCollcectionCount({ commit }) {
            axios.get(api.COLLECTNUM)
                .then(res => {
                    if (res.data.code == 200) {
                        commit("setCollectcounts", res.data.data);
                    } else {
                        alert(res.data.errorMessage);
                        router.push({ name: "error-500" });
                    }
                })
                .catch(err => {
                    alert(err);
                    router.push({ name: "error-500" });
                });
        },
        // 点击收藏 1收藏 0取消
        collectProdcut({ dispatch }, { productIdentity, likeStatus }) {
            axios.post(api.COLLECTPRODUCT, { productIdentity, likeStatus: likeStatus ? 1 : -1 }).then(res => {
                if (res.data.isSuccess) {
                    dispatch('getCollcectionCount')
                    if (router.currentRoute.path === '/user/myfavourites') {
                        dispatch('getCollectData')
                    }
                } else {
                    alert(res.data.errorMessage)
                }
            })
        },

        // 获取收藏列表
        getCollectData({ commit }) {
            axios.get(api.GETCOLLECTPRODUCT).then(res => {
                if (res.data.code == 200) {
                    const productList = res.data.data.productList?.map(function (val) {
                        return {
                            ...val,
                            price: val.priceType,
                            name: val.productName,
                            company: val.publisherDisplayName,
                            path: '/marketplace/apps/detail',
                            imgUrl: val.smallIcon,
                            paramsData: {
                                id: val.id
                            },
                            desc: val.offerSummary,
                            createUrl: val.createAppUrl ? val.createAppUrl : null,
                        }
                    });
                    commit('setCollectionProductList', productList)
                } else {
                    alert(res.data.errorMessage)
                }

            }).catch(err => {
                console.log('error')
                // this.$router.push({name:'error-500'})
            })
        }
    },
    modules: {},
    //使用localStorage来防止刷新数据丢失
    plugins: [
        createPersistedState({
            storage: window.sessionStorage
        })
    ]
})
