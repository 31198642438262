/*
 * @Author: your name
 * @Date: 2020-07-03 11:37:29
 * @LastEditTime: 2023-10-20 13:41:05
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \AMP-BC-FE\src\utils\request\api.js
 */
// const baseUrl = 'https://ampuat-api.21vbluecloud.com/api/'//测试环境
const baseUrl = 'https://amp-web-api.21vbluecloud.com/api/'//正式环境
const jarvis = 'https://solutionsupport.21vbluecloud.com/api/'
// const jarvis = 'https://solutionsupporttest.21vbluecloud.com/api/'
const jarvisToken = 'AMPToJarvisAPI/token'
const jarvisUpload = 'AMPToJarvisAPI/UploadFile'
const jarvisCreate = 'AMPToJarvisAPI/Create'

const UploadLicense = 'RuZhuXinXi/UploadFile'
const REGISTER = 'RuZhuXinXi/Create'
const SENDVERIFYCODE = 'SMS/SendVerifyCode'

const USERINFORMATION = 'User/Registered'
const GETFEATUREPRODUCT = 'Product/GetHomePageFeatureProductList' //获取首页热门推荐
const GETALLPRODUCTLIST = "Product/GetAllAppsProductList"//全部商品
const GETCATEGORYLIST = 'Category/GetCategoryList'//产品类别和筛选的列表
const GETHOTPRODUCTLIST = 'Product/GetCategoryHotProductList'//点击一级标题筛选的结果接收参数categoryId
const FILTERPRODUCT = 'Product/GetAllProductList'//筛选
const SEARCHPRODUCT = 'Product/GetProductSearchList'
const GETHOTCATEGORY = 'Product/GetHomePageHotCategory'//获取首页热门应用类别
const GETPRODUCTDETAIL = 'Product/GetProductById'//获取产品详情
const GETHOMEPAGESOLUTION = 'Product/GetHomePageSolutionProductList' //首页解决方案专区
const LEADINFORMATION = 'Lead/AddLead' //反馈表单
const DEPLOY = 'Lead/AddContactLead'
const LOGIN = 'User/GetOAuthUrl'
const GETUSERINFO = 'User/GetUserInfo'//获取用户登录信息
const COLLECTPRODUCT = 'ProductCollection/LikeOrNot'//收藏
const GETCOLLECTPRODUCT = 'ProductCollection/GetCollectionProducts'//获取收藏产品
const COLLECTNUM = 'ProductCollection/GetCollectionProductsCount'
export default {
    baseUrl,
    USERINFORMATION,
    GETFEATUREPRODUCT,
    GETALLPRODUCTLIST,
    GETCATEGORYLIST,
    FILTERPRODUCT,
    GETHOTPRODUCTLIST,
    SEARCHPRODUCT,
    GETHOTCATEGORY,
    GETPRODUCTDETAIL,
    GETHOMEPAGESOLUTION,
    LEADINFORMATION,
    DEPLOY,
    jarvis,
    jarvisToken,
    jarvisCreate,
    jarvisUpload,
    LOGIN,
    GETUSERINFO,
    COLLECTPRODUCT,
    GETCOLLECTPRODUCT,
    COLLECTNUM,
    REGISTER,
    SENDVERIFYCODE,
    UploadLicense
}
