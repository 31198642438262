<template>
    <div class="login">
        <p class="txt1">{{$t('Login.login_1')}}</p>
        <p class="txt2">{{$t('Login.login_2')}}</p>
        <el-form :model='form'  ref='form' @submit.native.prevent>
            <el-form-item prop='email'>
                <el-input v-model="form.email" placeholder="someone@example.partner.onmschina.cn" @keyup.enter.native="login()"></el-input>
            </el-form-item>
        </el-form>
        <div :class="form.email==''?['btn','btn_not']:'btn'" @click="login">{{$t('Login.btn')}}</div>
        <p class="txt3">{{$t('Login.login_3')}}</p>
        <a href="https://www.microsoft.com/china/azure/index.html?fromtype=cn#azurefreeform">
            <span class="txt4">{{$t('Login.login_4')}}</span>
            <i class="iconfont  icon-xiangyou"></i>
        </a>
    </div>
</template>

<script>
export default {
    name: 'Login',
    data() {
        return {
            form:{
                email: '',
            }
        }
    },
    methods: {
        login() {
            var url=this.Encrypt(location.href);
            if (this.form.email!=""){
                this.$http.get(this.$api.LOGIN, {
                    params: {
                        email: this.form.email,
                        path:url
                    }
                }).then(res => {
                    location.href = res.data;
                    this.form.email=""
                }).catch(err=>{
                    alert(err)
                    this.$router.push({name:'error-500'})
                });
            }
        },
        //字符串加密
        Encrypt(str, pwd) {
            if (str == "") return "";
            str = escape(str);
            if (!pwd || pwd == "") { var pwd = "1234"; }
            pwd = escape(pwd);
            if (pwd == null || pwd.length <= 0) {
                alert("Please enter a password with which to encrypt the message.");
                return null;
            }
            var prand = "";
            for (var I = 0; I < pwd.length; I++) {
                prand += pwd.charCodeAt(I).toString();
            }
            var sPos = Math.floor(prand.length / 5);
            var mult = parseInt(prand.charAt(sPos) + prand.charAt(sPos * 2) + prand.charAt(sPos * 3) + prand.charAt(sPos * 4) + prand.charAt(sPos * 5));
            var incr = Math.ceil(pwd.length / 2);
            var modu = Math.pow(2, 31) - 1;
            if (mult < 2) {
                alert("Algorithm cannot find a suitable hash. Please choose a different password. /n Possible considerations are to choose a more complex or longer password.");
                return null;
            }
            var salt = Math.round(Math.random() * 1000000000) % 100000000;
            prand += salt;
            while (prand.length > 10) {
                prand = (parseInt(prand.substring(0, 10)) + parseInt(prand.substring(10, prand.length))).toString();
            }
            prand = (mult * prand + incr) % modu;
            var enc_chr = "";
            var enc_str = "";
            for (var I = 0; I < str.length; I++) {
                enc_chr = parseInt(str.charCodeAt(I) ^ Math.floor((prand / modu) * 255));
                if (enc_chr < 16) {
                    enc_str += "0" + enc_chr.toString(16);
                } else
                enc_str += enc_chr.toString(16);
                prand = (mult * prand + incr) % modu;
            }
            salt = salt.toString(16);
            while (salt.length < 8) salt = "0" + salt;
            enc_str += salt;
            return enc_str;
        }
    }
}
</script>

<style  scoped>
.login {
    text-align: left!important;
}
.login .txt1 {
    font-size: 20px;
    font-weight: bold;
    /* margin-left: 95px; */
}
.login .txt2 {
    font-size: 12px;
    font-weight: 400;
    margin-top: 49px;
    /* margin-left: 94px; */
}
.el-input {
    width: 75%;
    height: 34px;
    margin-top: 17px;
    /* margin-left: 95px; */
}
.icon-youxiang {
    font-size: 11px;
    display: inline-block;
    margin-top: 14px;
    /* margin-left: 12px; */
}
.el-input /deep/ .el-input__inner {
    padding-left: 2px;
    border-radius: 0;
    width: 100%;
    font-size: 12px;
}
.vertical {
    color: #999999;
    margin-left: 5px;
    font-size: 14px;
    opacity: 0.32;
}
.btn {
    width: 173px;
    height: 34px;
    margin-top: 32px;
    /* margin-left: 95px; */
    border-radius: 0;
    background: #2e79d0;
    color: #fff;
    line-height: 34px;
    padding: 0;
    text-align: center;
    cursor: pointer;
}
.login>.btn_not{
    cursor: not-allowed;
    background: grey;
}
.login .txt3 {
    font-size: 13px;
    font-weight: 400;
    margin-top: 35px;
    /* margin-left: 96px; */
    margin-bottom: 8px;
}
.txt4 {
    font-size: 13px;
    font-weight: 400;
    /* margin-left: 96px; */
    color: #1676f6;
    cursor: pointer;
}
.icon-xiangyou {
    width: 18px;
    height: 18px;
    margin-left: 8px;
    color: #1676f6;
}

</style>
